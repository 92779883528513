/*
 * @Author: stephenHe
 * @Date: 2022-04-03 19:36:39
 * @LastEditors: stephenHe
 * @LastEditTime: 2022-04-04 01:49:41
 * @Description:
 * @FilePath: /code/exercise/my-app-ts/src/App.tsx
 */

import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

const axios = require("axios").default;

function App() {
  const [data, setData] = useState<any>();

  useEffect(() => {
    axios
      .get("https://my-ts-project.stephenhe.workers.dev/info")
      .then(
        ({ data }: any) => {
          setData(data);
        },
        (reason: any) => {
          console.log(reason);
        }
      )
      .catch(function (error: any) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        // debugger;
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p> */}
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ul>
            <li>
              {/* <p>name:</p> */}
              <p>{data?.name}---</p>
            </li>
            <li>
              {/* <p>country:</p> */}
              <p>{data?.country}</p>
            </li>
            <li>
              {/* <p>city:</p> */}
              <p>{data?.city}</p>
            </li>
            <li>
              {/* <p>count:</p> */}
              <p>{data?.count}</p>
            </li>
            <li>
              {/* <p>desc:</p> */}
              <p>{data?.desc}</p>
            </li>
          </ul>
        </a>
      </header>
    </div>
  );
}

export default App;
